.ProjectImage{
  max-width: 100%;
  width: 300px;
  height: 300px;
  align-self: center;
}

.ProjectDescription{
  width: min-content;
  justify-content: center;
  min-width: -webkit-fill-available;
}

.ProjectCards{
  margin-top: 8%;
  margin-bottom: 8%;
  min-width: max-content;
  font-family: Roborto;
  font-size: large;
}

.ProjectCardTitle{
  font-family: Roborto;
}