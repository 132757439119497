

.TechnologyListItem{
  font-size: x-large;
  display: inline-flex;
  font-family: Roborto;
  border-style: inset;
  border-width: thick;
  background-color: whitesmoke;
  padding-right: 6px;
  margin-right: 1%;
}

