.card-body {
  padding: 15px;
  background-color:#fff;
  width:100%;
}

.body-content {
  padding-bottom: 40px;
  font-size: x-large;
  line-height: 1.8;
  font-family: Roborto;
}

.body-quote {
  padding-bottom: 40px;
  font-size: Larger;
  line-height: 1.8;
  font-family: cursive;
}

.aboutUsCardTitles{
  font-family: Roborto;
}