.card-header {
  height: 150px;
  width: 100%;
  padding: 15px;
  width:100%;
  color:#fff;
}

url {
  margin-right: 20%;
  height: -webkit-fill-available;
  object-fit: cover;
}

.card-header--title {
  text-transform: uppercase;
  margin: 0;
}