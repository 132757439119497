.Porfolio{
  font-family: fantasy;
  background-color: whitesmoke;
  justify-content: center;
  font-family: Roborto;
}

.ProjectRow{
  border-style: inset;
  border-width: thick;
  border-color: gray;
  justify-content: center;
  font-family: Roborto;
}